
















import { Component, Vue } from "vue-property-decorator";
@Component({
	props: {
		value: {
			type: Number,
			default: 0,
		},
	},
})
export default class TextFieldFloat extends Vue {
    private text = "";
    private content 
	private created() {
        this.initSelf();
	}
    private getValue(){
        return ""+this.$props.value;
    }
    private initSelf(){
        this.text = ""+this.getValue();
        this.inputEvent();
    }
    private inputEvent(){
        console.log("inutEvent");
        if(this.text != null)
            console.log(this.text,"startText");
        //this.text = ""+this.getVal();
        //console.log(this.text,"startText2");
        
        let result = "";
        let nums = "";
        let numsAfterDot = "";
        let dotExist = false;
        //console.log(this.text.length,"startText len");
        if(this.text != null){
            for(let i = 0; i < this.text.length; i++){
            if(this.isNumber(this.text[i]) && !dotExist){
                nums += this.text[i];
            }
            else if(this.isNumber(this.text[i]) && dotExist){
                numsAfterDot += this.text[i];
            }
            else if(this.text[i] == '.'){
                dotExist = true;
            }
            }
            console.log("nums", nums);
            let totalLength = 0;
            totalLength += nums.length;
            totalLength += numsAfterDot.length;
            if(dotExist)
                totalLength += 1;

            // кол-во цифр в числе до первого пробела 
            let d = nums.length % 3;
            console.log("d", d);
            let k = 0;
            let dZero = false;
            if(d == 0)
                dZero = true;
            for(let i = 0; i < nums.length; i++){
                if(k < d){
                    result += nums[i];
                    k++;
                }
                else{
                    if(!dZero){
                        result += " ";
                        result += nums[i];
                        k = 0;
                        d = 2;
                    }else{
                        result += nums[i];
                        k = 0;
                        d = 2;
                        dZero = false;
                    }
                }
            }
            if(dotExist){
                result += ".";
                dotExist = true;
            }
            if(dotExist)
                for(let i = 0; i < numsAfterDot.length; i++){
                    result += numsAfterDot[i];
                }
            
            console.log("numsAD", numsAfterDot);
            
            this.text = result;
            let res = this.getNumber(this.text);
            this.$emit('input', res);
            console.log(this.text, "text");
            console.log(res, "result");
            this.$forceUpdate();
        }
        else{
            this.text = "";
        }
    }
    private redraw(txt){
        console.log("REDRAW", this.text);
        console.log("REDRAW txt", txt);
        let t = "" + this.text;
        this.text = "0";
        console.log("REDRAW", this.text);
        this.$forceUpdate();
        setTimeout(() => {
            this.text = ""+txt;
            console.log("REDRAW", this.text);
            this.$forceUpdate();
            this.inputEvent();
        }, 500)
    }
    private isNumber(str){
        return /^\d+$/.test(str);
    }
    private isValid(str){
        if(/^\d+$/.test(str) || str==".")
            return true;
        return false;
    }
    private blurEvent(){
        let t = ""+this.text as any;
        console.log("Text:", t);
        this.text = "";
        let valid = false;
        for(let i = 0; i < t.length; i++){
            if(this.isValid(t[i]))
                valid = true;
        }
        if(!valid){
            t = null;
        }
        setTimeout(()=>{
            this.text = t;
            this.$forceUpdate();
            console.log("Text:", this.text);
        }, 1);
    }
    private getNumber(str){
        let result = "";
        for(let i = 0; i < str.length; i++){
            if(this.isNumber(str[i]) || str[i] == ".")
                result += str[i];
        }
        return parseFloat(result);
    }
}
