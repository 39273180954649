








































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  Indicator,
  IndicatorTable,
  IndicatorTableRow,
} from "@/models/Indicator";
import {
  INDICATOR_TABLE_REQUEST,
  INDICATOR_TABLE_CU,
  INDICATOR_TABLE_DELETE,
  INDICATOR_TABLE_UPDATED,
  INDICATOR_TABLE_LOADED,
} from "@/store/actions/indicatorTable";
import { Region } from "@/models/Region";
import { REGION_REQUEST, REGION_LOADED } from "@/store/actions/main";
import { INDICATOR_REQUEST } from "@/store/actions/indicator";
import TextFieldFloat from "@/components/TextFieldFloat.vue";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const decimalMask = createNumberMask({
    prefix:"",
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: true,
    thousandsSeparatorSymbol: " ",
    decimalLimit: 3
  });
@Component({
  components: {
		TextFieldFloat,
	},
})
export default class IndicatorsTables extends Vue {
  private indicators: Indicator[] = [];
  private regions: any = [];
  private rows: any = [];
  private form: any = {};
  private tableVisible = true;
  private rowsHeaders = [
    {
      text: "Показатель",
      value: "indicator_id",
      align: "center",
      width: "25%",
    },
    {
      text: "План",
      value: "indicator_planned",
      align: "center",
      width: "10%",
    },
    {
      text: "Фактически",
      value: "indicator_actual",
      align: "center",
      width: "10%",
    },
    {
      text: "Процент",
      value: "indicator_percent",
      align: "center",
      sortable: false,
      width: "10%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "5%",
    },
  ];
  private show: boolean = true;
  private loading: boolean = false; //Заглушка таблицы
  private itemId: number = 0;
  private mask = decimalMask;
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  private loadingBtn = false; //Заглушка кнопок
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = -1;

  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.INDICATOR_TABLE,
        this.$store.getters.INDICATOR_LOADED
      )
    );
    needReload.push(
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		);
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;
    if (
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		) {
			this.$store.dispatch(REGION_REQUEST).then(() => {
				this.initIfReady();
			});
		}
    if (
      this.needReload(
        this.$store.getters.INDICATOR_TABLE,
        this.$store.getters.INDICATOR_LOADED
      )
    ) {
      this.$store.dispatch(INDICATOR_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
  private initSelf() {
    console.log("INIT SELF");
    this.indicators = this.$store.getters.INDICATOR_TABLE.data;
    this.regions = this.$store.getters.REGION_TABLE.data;
    this.initStartOptions();
    this.loading = false;
    console.log("loading");
  }
  //Methods
  private test(){
    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", this.rows)
  }
  private isUpdatePage() {
    return this.$route.meta.isUpdate;
  }
  private addRow() {
    let nums = [] as number[];
    let max = 0;
    for(let i = 0; i < this.rows.length; i++){
      if(max < this.rows[i].num){
        max = this.rows[i].num;
      }
    }
    max++;
    this.rows.push({
      num: max,
    });
  }
  private deleteRow(num) {
    console.log("num to del", num);
    let redrawNum = 0;
    for (let i = 0; i < this.rows.length; i++) {
      if (this.rows[i].num == num) {
        console.log("I = ", i);
        this.rows.splice(i, 1);
      }
    }
    this.tableVisible = false;
    setTimeout(()=>{
      this.tableVisible = true;
    }, 1);
  }
  // private redrawRows(i){
  //   console.log("NUM = ", i);
  //   if (i < this.rows.length) {
  //     let v = Object.assign({}, this.rows[i]);
  //     console.log("variable V = ", v);
  //     this.rows[i] = {};
  //     setTimeout(() => {
  //         this.rows[i] = Object.assign({}, v);
  //         console.log("works");
  //     },1);
  //     console.log("if NUM 2 = ", i);
  //   }    
  // }
  private addItem() {
    this.loadingBtn = true;
    this.form.indicator_rows = this.rows;
    var arr = this.getCleanFields();
    var f = Object.assign({}, this.form);
    f.indicator_rows = arr;
    console.log("form", f);
    // if (this.form.indicator_rows !== undefined)
    //   for (let i = 0; i < this.form.indicator_rows.length; i++) {
    //     delete this.form.indicator_rows[i].num;
    //   }
    delete f.id;
    console.log("form", f);
    this.$store
      .dispatch(INDICATOR_TABLE_CU, this.form)
      .then(() => {
        this.messageText =
          "Таблица " + this.form.indicator_table_name + " добавлена.";
        this.messageColor = "green";
        this.tryToInitSelf();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
        this.loadingBtn = false;
      });
  }
  private updateItem() {
    this.loadingBtn = true;
    this.form.indicator_rows = this.rows;
    
    // for(let i = 0; i < this.form.indicator_rows.length; i++){
    //   delete this.form.indicator_rows[i].num;
    // }

    var arr = this.getCleanFields();
    var f = Object.assign({}, this.form);
    f.indicator_rows = arr;
    console.log("form", f);
    this.$store
      .dispatch(INDICATOR_TABLE_CU, f)
      .then(() => {
        this.messageText =
          "Таблица " + this.form.indicator_table_name + " изменена.";
        this.messageColor = "green";
        this.tryToInitSelf();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
        this.loadingBtn = false;
      });
  }
  private getCleanFields() {
    var arr = [] as any;
    for (let i = 0; i < this.rows.length; i++) {
      let p = this.toString(this.rows[i].indicator_planned);
      let a = this.toString(this.rows[i].indicator_planned);
      let per = this.toString(this.rows[i].indicator_planned);
      arr.push({
        indicator_id: this.rows[i].indicator_id,
        indicator_planned: +p.replace(' ',''),
        indicator_actual: +a.replace(' ',''),
        indicator_percent: +per.replace(' ',''),
      });
    }
    return arr;
  }
  private toString(str) {
    return str as string;
  }
  private formatField(field, num, name)
  {
    console.log('is Complete', num);
    field.target.value.replace('.',',');
    var a = field.target.value;
    a = a.replace(' ','');
    field.target.value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    let f = a.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    for(let i = 0; i < this.rows.length; i++){
      if(num == this.rows[i].num){
        this.rows[i][name] = f;
        break;
      }
    }
    console.log("field", f);
  }
  private initStartOptions() {
    var item;
    if (localStorage.getItem("indicatorsTablesToEdit")) {
      console.log("update page");
      try {
        item = JSON.parse(
          this.toString(localStorage.getItem("indicatorsTablesToEdit"))
        );
        console.log("item init: ", item);
      } catch (e) {
        localStorage.removeItem("indicatorsTablesToEdit");
      }
    } else {
      console.log("create page");
    }
    if (this.isUpdatePage()) {
      this.form.region_id = item.region_id;
      this.form.id = item.id;
      this.form.indicator_table_name = item.indicator_table_name;
      this.form.region_id = item.region_id;
      this.rows.length = 0;
      for (let i = 0; i < item.indicator_rows.length; i++) {
        this.rows.push({
          num: this.rows.length,
          indicator_id: item.indicator_rows[i].indicator_id,
          indicator_planned: item.indicator_rows[i].indicator_planned,
          indicator_actual: item.indicator_rows[i].indicator_actual,
          indicator_percent: item.indicator_rows[i].indicator_percent,
        });
      }
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 1);
      console.log("rows", this.rows);
      this.$forceUpdate();
    }
  }
  //Hooks
  private created() {
    var item;
    if (localStorage.getItem("indicatorsTablesToEdit")) {
      item = JSON.parse(
        this.toString(localStorage.getItem("indicatorsTablesToEdit"))
      );
      this.$router.currentRoute.meta.breadcrumb[2].text = item.indicator_table_name;
    }
  }
  private mounted() {
    this.tryToInitSelf();
  }
}
